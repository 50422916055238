body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.title {
  width: 100%;
  text-align: center;
  padding-top: 15px;
}

.powered-info {
  font-size: 1rem;
}

.data-description {
  font-style: italic;
}

.show-column {
  /* display:inline-block; */
}

.hide-column {
  display: none;
}

.footer-container {
  display: none;
}

@media only screen and (max-width: 560px) {
  .powered-info {
    display: block;
  }

  .addUser-container {
    margin-left: 25px;
  }

  .footer-container {
    display: block;
    text-align: center;
    background: #96a1b4;
    color: #f0f0f0;
    padding: 5px;
  }
}
